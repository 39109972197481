import {
    Typography,
    Layout,
    Row,
    Col,
    Affix,
    Image,
    Button,
    Tooltip,
    Popover,
} from 'antd'

import {
    DownloadOutlined,
} from '@ant-design/icons'

import {
    motion,
} from 'framer-motion'

import {
    useContext,
} from 'react'

import { Context } from './store/Context'
import Form from './form';

// props: sectionItem, backgroundColor
function SectionItem(props) {
    return (
        <Row justify='center' align='top' style={{ backgroundColor: props.backgroundColor, padding: '20px' }}>
            <motion.div 
                initial={{ x: -300, opacity: 0 }} 
                whileInView={{ x: 0, opacity: 1, transition: { type: 'spring', bounce: 0, duration: 1 } }} 
                viewport={{ once: true }}
                style={{ width: '100%', maxWidth: '600px', margin: '10px auto' }}
            >
                <Row justify='center'>
                    <Typography.Title level={2} style={{ textAlign: 'center' }}>
                        {props.sectionItem.title}
                    </Typography.Title>
                </Row>
                <Row justify='center' style={{ margin: '10px 0' }}>
                    <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
                        {props.sectionItem.text}
                    </Typography>
                </Row>
                <Row justify='center'>
                    <Image
                        preview={false}
                        src={props.sectionItem.image}
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '600px',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        }}
                    />
                </Row>
            </motion.div>
        </Row>
    )
}

// props: sectionList
function SectionList(props) {
    return (
        <>
            {
                props.sectionList.map((sectionItem, index) => {
                    return (
                        <SectionItem key={index} sectionItem={sectionItem} backgroundColor={index % 2 === 0 ? 'white' : null} />
                    )
                })
            }
        </>
    )
}

function Mobile() {
    const { state } = useContext(Context)

    return (
        <Layout style={{ overflowX: 'hidden' }}>
            <Affix offsetTop={0}>
                <Layout.Header style={{ background: 'white', width: '100%' }}>
                    <Row justify='center' align='middle' style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                        <Col>
                            <Image width={30} height={30} preview={false} src={state.appLogo} />
                        </Col>
                        <Col>
                            <Typography.Title level={3} style={{ color: 'black', marginLeft: '10px' }}>{state.appName}</Typography.Title>
                        </Col>
                    </Row>
                </Layout.Header>
            </Affix>

            <Layout.Content>
                {/* Cover headline */}
                <Row justify='center' align='middle' style={{ backgroundColor: 'white', paddingTop: '50px' }}>
                    <Col xs={24} sm={24}>
                        <Row justify='center'>
                            <Typography.Title level={2} style={{ textAlign: 'center' }}>
                                {state.coverTitle}
                            </Typography.Title>
                        </Row>

                        <Row justify='center' style={{ padding: '0px 20px' }}>
                            <Typography.Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>
                                {state.coverText1}
                            </Typography.Paragraph>
                            <Typography.Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>
                                {state.coverText2}
                            </Typography.Paragraph>
                            <Typography.Paragraph style={{ fontSize: '16px', textAlign: 'center' }}>
                                {state.coverText3}
                            </Typography.Paragraph>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} style={{ marginTop: '-100px' }}>
                        <Row justify='center'>
                            <Image
                                preview={false}
                                src={state.coverImage}
                                style={{ width: '100%', height: 'auto', maxWidth: '1000px' }}
                            />
                        </Row>
                    </Col>
                </Row>

                {/* Endorsement list */}
                <Row justify='center' align='top' style={{ padding: '70px 20px' }}>
                    <motion.div
                        initial={{ x: -300, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1, transition: { type: 'spring', bounce: 0, duration: 1 } }}
                        viewport={{ once: true }}
                        style={{ width: '100%', maxWidth: '600px' }}
                    >
                        <Row justify='center'>
                            <Typography.Title level={2} style={{ textAlign: 'center' }}>
                                {state.endorsementTitle}
                            </Typography.Title>
                        </Row>
                        <Row justify='center'>
                            <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
                                {state.endorsementText}
                            </Typography>
                        </Row>
                        <Form />
                    </motion.div>
                </Row>

                {/* Section list */}
                <SectionList sectionList={state.sectionList} />

                <Row justify='center' align='middle' style={{ backgroundColor: 'white', padding: '0 0 40px 0' }}>
                    <Col>
                        <Typography.Text type='secondary' style={{ fontSize: 12 }}>
                            {state.appName} © {new Date().getFullYear()}
                        </Typography.Text>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    )
}

export default Mobile
