import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { collection, addDoc } from 'firebase/firestore'; // Firestore methods
import { db } from './firebase'; // Adjust this path to your Firebase config file

const Form = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage("");  // Clear any previous messages

    try {
      const docRef = await addDoc(collection(db, "subscribers"), {
        email: email,
        timestamp: new Date()
      });
      setMessage("Thanks for subscribing!");
      setEmail("");
    } catch (error) {
      console.error("Error adding document:", error);
      setMessage("Failed to subscribe, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
        <TextField
        label="Email Address"
        type="email"
        variant="outlined"
        fullWidth
        required
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputLabelProps={{
            required: false  // This removes the asterisk but keeps the field required for HTML5 validation
        }}
        sx={{ mb: 2 }}  // Margin bottom for spacing
        />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={email === '' || loading}
        sx={{ height: 56, fontSize: '1rem' }}  // Ensuring button matches TextField in height and font size
      >
        {loading ? 'Subscribing...' : 'Join Mailing List'}
      </Button>
      <div style={{ marginTop: '10px', color: message.startsWith('Thanks') ? 'green' : 'red', fontStyle: 'italic' }}>
        {message}
      </div>
    </form>
  );
};

export default Form;
